.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #262628;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  padding: 12px;
  background-color: #121212;
}

.Standings-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  padding: 12px;
  background-color: #121212;
}

/* Media query for laptop screens (adjust the screen size accordingly) */
@media (min-width: 850px) {
  .Standings-table {
    width: 50%; /* 50% width on laptop screens */
    max-width: none; /* Remove the maximum width constraint */
  }
}

.App-th {
  color: #c5c5c5;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  position: relative;
  border-top: 1px solid #282c34;
}

.td-border-top {
  border-top: 1px solid #282c34;
}

.App-td {
  color: #c5c5c5;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  position: relative;
}

.biggest-loser {
  background-color: #e24017;
}

.final-score {
  font-weight: 600;
}

.header-bold {
  font-weight: 800;
}

.pick-nickname {
  background-color: #121212;
  background: whitesmoke;
  color: black;
  padding-left: 2px;
  padding-right: 2px;
  font-family: "Copperplate";
  font-size: 14px;
  font-weight: 600;
}

.App-td-winner {
  color: #509e46;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  position: relative;
}

.final-winner {
  color: #509e46;
  font-weight: 999;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.bottom-border {
  border-bottom: 1px solid #282c34;
}

.App-th-date {
  color: #c5c5c5;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  position: relative;
  border-top: 1px solid #282c34;
}

.App-tr-spacer {
  background-color: #282c34;
}

.App-tr-breakline {
  border-top: 1px solid #282c34;
}

.App-ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: flex-start;
  justify-content: left;
  padding-left: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
